import React, { useEffect } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import { ThemeProvider } from "styled-components"
import { MuiThemeProvider, StylesProvider } from "@material-ui/core/styles"
import theme from "../styles/theme/index.js"
import GlobalCss from "../styles/theme/global"
import Hero from "../components/Hero"
import Article from "../components/Article"
import ArticlePagination from "../components/ArticlePagination"
import SEO from "../components/SEO"

const ArticlePage = ({ data, location, pageContext }) => {
  const { contentfulBlogArticle, contentfulHero } = data
  const { prev, next } = pageContext
  const {
    title,
    author,
    image,
    dateOfPublication,
    synopsis: { synopsis },
  } = contentfulBlogArticle
  const canonicalUrl = data.site.siteMetadata.siteUrl + location.pathname
  useEffect(() => {
    if (!location.hash) {
      window.scrollTo(0, 0)
    }
  }, [location])
  const metadata = {
    seoTitle: title,
    seoDescription: { seoDescription: synopsis },
    seoImage: image,
  }
  // Hero is managed by an orphaned coontentful Hero, to make updating across all blog posts much easier:
  // https://app.contentful.com/spaces/8xcltilg687t/entries/5jHlMGBkRtEx3IBgFlPbRy
  const heroData = {
    ...contentfulHero,
    ctaBlock: {
      ...contentfulHero.ctaBlock,
      title,
      author: {
        author,
        dateOfPublication,
      },
    },
    media: {
      ...contentfulHero.media,
      media: image,
      polariod: true,
    },
  }
  return (
    <>
      <SEO data={metadata} canonicalUrl={canonicalUrl} article />
      <StylesProvider>
        <MuiThemeProvider theme={theme}>
          <ThemeProvider theme={theme}>
            <GlobalCss />
            <Layout hasHero={true}>
              <Hero data={heroData} />
              <Article data={contentfulBlogArticle} />
              <ArticlePagination prev={prev} next={next} />
            </Layout>
          </ThemeProvider>
        </MuiThemeProvider>
      </StylesProvider>
    </>
  )
}

export const query = graphql`
  query article($id: String) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    contentfulHero(contentful_id: { eq: "5jHlMGBkRtEx3IBgFlPbRy" }) {
      ctaBlock {
        title
        titleMarkup
        description {
          raw
        }
        overline
        cta {
          text
          variant
          colour
          gtagEventId
          reference {
            __typename
            ... on ContentfulExternalLink {
              __typename
              linkText
              linkRef
            }
            ... on ContentfulPage {
              __typename
              slug
            }
            ... on ContentfulSection1Col {
              __typename
              anchorIdentifier
            }
            ... on ContentfulSection2Col {
              __typename
              anchorIdentifier
            }
          }
        }
      }
      media {
        media {
          gatsbyImageData(layout: CONSTRAINED, placeholder: NONE)
          title
        }
        polariod
        maxWidth
      }
      mediaSize
      background
      backgroundMedia {
        file {
          url
          fileName
          contentType
          details {
            image {
              width
              height
            }
          }
        }
      }
      invertTextColour
      banner {
        content {
          title
          images {
            gatsbyImageData(layout: CONSTRAINED, placeholder: NONE)
            title
          }
        }
      }
      video
    }
    contentfulBlogArticle(id: { eq: $id }) {
      id
      title
      dateOfPublication(formatString: "DD MMMM YYYY")
      author {
        name
        image {
          gatsbyImageData(layout: CONSTRAINED, placeholder: NONE)
          svg {
            content
          }
        }
        description {
          description
        }
        occupation
      }
      tags {
        title
        slug
      }
      image {
        file {
          url
        }
        gatsbyImageData(layout: CONSTRAINED, placeholder: NONE)
      }
      synopsis {
        synopsis
      }
      articleBody {
        raw
        references {
          ... on ContentfulAsset {
            # contentful_id is required to resolve the references
            __typename
            contentful_id
            gatsbyImageData(layout: CONSTRAINED, placeholder: NONE)
          }
          ... on ContentfulVideoFeatured {
            __typename
            contentful_id
            id
            title
            featuredVideo
          }
          ... on ContentfulMedia {
            __typename
            contentful_id
            id
            media {
              gatsbyImageData(layout: CONSTRAINED, placeholder: NONE)
            }
            maxWidth
            polariod
          }
        }
      }
    }
  }
`

export default ArticlePage
